<template>
  <div>
    <h1>¡Bienvenid@!</h1>
    <h3>- OptiFlow Team</h3>
  </div>
</template>

<script>
export default {
  name: "DashboardPage",
};
</script>
